import { template as template_b7f1b48f0f8f4b6787c5850420e1bf3f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_b7f1b48f0f8f4b6787c5850420e1bf3f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
