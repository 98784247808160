import { template as template_b40c6c9b20e847b498c92a04aad236c6 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
import concatClass from "discourse/helpers/concat-class";
import number from "discourse/helpers/number";
const ViewsCell = template_b40c6c9b20e847b498c92a04aad236c6(`
  <td class={{concatClass "num views topic-list-data" @topic.viewsHeat}}>
    <PluginOutlet
      @name="topic-list-before-view-count"
      @outletArgs={{hash topic=@topic}}
    />
    {{number @topic.views numberKey="views_long"}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
